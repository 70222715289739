import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WebsiteImage from 'components/WebsiteImage';
import isNil from 'utils/isNil';

const CompanyTrustSection = ({ background, trustedSection, ...otherProps }) => {
  return (
    <SectionCompanyTrust background={background} {...otherProps}>
      <div className="section-company-trust--container">
        <h2 className="section-company-trust--title font-h1">{trustedSection.title}</h2>
        <div className="section-company--trusted-logo-content">
          {!isNil(trustedSection.imgs) &&
            trustedSection.imgs.map((item, index) => {
              return (
                <div className="section-company--trusted-logo-item" key={index}>
                  <WebsiteImage image={item} />
                </div>
              );
            })}
        </div>
      </div>
    </SectionCompanyTrust>
  );
};

CompanyTrustSection.propTypes = {
  title: PropTypes.string,
  trustedSection: PropTypes.object,
};

const SectionCompanyTrust = styled.div`
  background: ${(props) => props.background};

  .section-company-trust--container {
    padding: 81px 28px 110px;
    max-width: 1040px;
    margin: 0 auto;
    text-align: center;

    .section-company-trust--title {
      text-align: center;
      color: var(--darkest);
    }

    .section-company--trusted-logo-content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .section-company--trusted-logo-item {
        margin-right: 30px;
        text-align: center;
        width: 120px;

        img {
          max-width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-company-trust--container {
      padding: 48px 18px;

      .section-company--trusted-logo-content {
        margin-top: 20px;
        flex-direction: column;
        .section-company--trusted-logo-item {
          margin: 48px 15px 0;
          padding: 0px 10px;
        }
      }
    }
  }
`;

export default CompanyTrustSection;
