import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WistiaVideo from 'components/Core/WistiaVideo';
import BackgroundImage from 'components/Core/BackgroundImage';
import contentfulRichTextOptions from 'components/RichText/options/contentfulRichTextOptions';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

const CompanyHeroSection = ({
  backgroundImg,
  backgroundColor,
  title,
  titleColor,
  description,
  descriptionColor,
  video,
  ...otherProps
}) => {
  const headingProps = {
    h2Size: 'h1',
    h3Size: 'h1',
    h4Size: 'h1',
    h5Size: 'h1',
    h6Size: 'h1',
    paragraphSize: 'h1',
    overrideType: 'h1',
    className: 'section-company-hero--title font-h1',
    color: 'white',
  };
  const subheadProps = {
    h1Size: 'p',
    h2Size: 'p',
    h3Size: 'p',
    h4Size: 'p',
    h5Size: 'p',
    h6Size: 'p',
    paragraphSize: 'p',
    overrideType: 'p',
    className: 'section-company-hero--description',
    color: 'white',
  };

  return (
    <SectionCompanyHero
      backgroundColor={backgroundColor}
      titleColor={titleColor}
      descriptionColor={descriptionColor}
      {...otherProps}
    >
      <div className="section-company-hero--container">
        {title && renderRichText(title, contentfulRichTextOptions(headingProps))}
        {description && renderRichText(description, contentfulRichTextOptions(subheadProps))}
        <BackgroundImage image={backgroundImg} loading="eager" />
        <WistiaVideo className="section-company-hero--video-button" url={video} buttonPopover />
      </div>
    </SectionCompanyHero>
  );
};

CompanyHeroSection.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  description: PropTypes.string,
  descriptionColor: PropTypes.string,
  backgroundImg: PropTypes.string,
  backgroundColor: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonType: PropTypes.string,
  buttonLinkTo: PropTypes.string,
};

const SectionCompanyHero = styled.div`
  padding: 0;

  .section-company-hero--container {
    padding: 389px 28px 230px;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    position: relative;

    .section-company-hero--title {
      color: ${(props) => props.titleColor};
      max-width: 990px;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }

    .section-company-hero--description {
      font-size: var(--font-size-h5);
      color: ${(props) => props.descriptionColor};
      max-width: 788px;
      margin: 40px auto;
      position: relative;
      z-index: 1;
    }

    .section-company-hero--video-button {
      margin-left: auto;
      margin-right: auto;

      & > button {
        margin: -50px -50px !important;
      }

      @media (max-width: 575px) {
        position: relative;
        height: auto;
        margin-top: 50px;
        button {
          right: 0;
        }
      }
      button {
        margin: 0 auto;
      }
    }
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    .section-company-hero--container {
      padding: 169px 20px 89px 20px;

      .section-company-hero--title {
        font-size: var(--font-size-28);
        letter-spacing: var(--letter-spacing--0-03);
        max-width: 590px;
      }

      .section-company-hero--description {
        font-size: var(--font-size-16);
        margin-top: 16px;
        max-width: 468px;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 0;

    .wistia-video--play-button {
      top: -30px !important;
    }
  }
`;

export default CompanyHeroSection;
