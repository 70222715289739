import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WebsiteImage from 'components/WebsiteImage';
import isNil from 'utils/isNil';

const CompanyLogoSection = ({ background, logos, ...otherProps }) => {
  return (
    <SectionCompanyLogo background={background} {...otherProps}>
      <div className="section-company-logo--container">
        <div className="section-company-logo--content">
          {logos.map((logoItem, index) => {
            return (
              <div className="section-company-logo--item" key={index}>
                <div
                  className={`section-company-logo--image-wrapper section-company-logo-custom-${index}`}
                >
                  <WebsiteImage image={logoItem} />
                </div>
                {!isNil(logoItem.description) && (
                  <p className="section-company-logo--description">{logoItem.description}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </SectionCompanyLogo>
  );
};

CompanyLogoSection.propTypes = {
  title: PropTypes.string,
  logos: PropTypes.array,
};

const SectionCompanyLogo = styled.div`
  background: ${(props) => props.background};

  .section-company-logo--container {
    padding: 98px 20px 130px;
    max-width: 1248px;
    margin: 0 auto;
    text-align: center;

    .section-company-logo--content {
      display: flex;

      .section-company-logo--item {
        padding: 40px 28px;
        width: 100%;
        border-right: 1px solid var(--grey-4);
        margin: 0 auto;

        .section-company-logo--image-wrapper {
          margin: 0 auto;
          align-items: center;
          justify-content: center;
          width: 100px;
          .gatsby-image-wrapper {
            img {
              width: 100%;
              object-fit: contain;
            }
          }
        }

        .section-company-logo--description {
          text-align: center;
          max-width: 316px;
          margin: 59px auto 0;
        }

        &:last-child {
          border: none;
        }
      }

      .section-company-logo-custom-0 {
        width: 260px !important;
        img {
          width: inherit;
        }
      }
      .section-company-logo-custom-1 {
        width: 260px !important;
        img {
          width: inherit;
        }
      }
    }
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    .section-company-logo--container {
      padding: 48px 28px;

      .section-company-logo--content {
        .section-company-logo--item {
          padding: 30px 50px 0;

          .section-company-logo--image-wrapper {
            width: 100%;
          }

          .section-company-logo--description {
            margin-top: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-company-logo--container {
      .section-company-logo--content {
        flex-direction: column;

        .section-company-logo--item {
          border-right: none;
          border-bottom: 1px solid var(--grey-4);
          padding: 40px 0;
          max-width: 279px;
        }
      }
    }
  }
`;

export default CompanyLogoSection;
