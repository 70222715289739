import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import useBreakpointView from 'utils/useBreakpointView';
import useIsClient from 'hooks/useIsClient';

const CompanyMilestonesSection = ({ background, title, milestones, ...otherProps }) => {
  const isMobile = useBreakpointView(['xs', 'sm']);
  const isClient = useIsClient();

  return (
    <SectionCompanyMilestones background={background} {...otherProps}>
      <div className="section-company-milestones--container">
        <h2 className="section-company-milestones--title">{title}</h2>

        <div className="section-company-milestones--content">
          {isClient && (
            <Timeline
              className="section-company-milestones--timeline"
              position={isMobile ? 'right' : 'alternate'}
              sx={
                isMobile
                  ? {
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }
                  : {}
              }
            >
              {milestones.map((milestoneItem, index) => {
                return (
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDot sx={{ boxShadow: '0' }} />
                      {index !== milestones.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent
                      key={index}
                      className={
                        !isMobile ? `item-${index % 2 === 0 ? 'right' : 'left'}` : `item-right`
                      }
                    >
                      <div className="section-company-milestones--item">
                        <div className="section-company-milestones--item-text">
                          <p className="section-company-milestones--item-date font-h5">
                            {milestoneItem.label}
                          </p>
                          <p className="section-company-milestones--item-description">
                            {milestoneItem.description}
                          </p>
                        </div>
                      </div>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          )}
        </div>
      </div>
    </SectionCompanyMilestones>
  );
};

CompanyMilestonesSection.propTypes = {
  title: PropTypes.string,
  milestones: PropTypes.array,
};

const SectionCompanyMilestones = styled.div`
  background: ${(props) => props.background};

  .section-company-milestones--container {
    padding: 96px 28px 144px;
    margin: 0 auto;

    .section-company-milestones--title {
      text-align: center;
    }

    .section-company-milestones--content {
      max-width: 920px;
      padding: 0;
      margin: 50px auto 0;
      .item-right {
        width: calc(50% - 4px) !important;
      }
      .item-left {
        left: calc(50% - 19px) !important;
        @media (max-width: 767px) {
          left: 0 !important;
          margin: 0 0 0 10px;
        }
      }

      .section-company-milestones--timeline {
        .section-company-milestones--item {
          display: flex;
          align-items: start;

          .section-company-milestones--item-text {
            .section-company-milestones--item-date {
              margin: 0;
            }

            .section-company-milestones--item-description {
              margin: 10px 0 0;
            }
          }
        }
        .MuiTimelineDot-root {
          background-color: var(--titan-blue-3);
        }

        .item-left {
          .section-company-milestones--item {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-company-milestones--container {
      padding: 48px 28px;

      .section-company-milestones--content {
        max-width: 606px;
        margin-top: 30px;
        padding: 0;

        .section-company-milestones--timeline {
          .section-company-milestones--item {
            .section-company-milestones--item-text {
              .section-company-milestones--item-description {
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
`;

export default CompanyMilestonesSection;
