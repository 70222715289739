import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import WebsiteImage from 'components/WebsiteImage';
import Button from 'components/Core/Button';

const CompanyFounderSection = ({
  background,
  title,
  founders,
  buttonTitle,
  buttonType,
  buttonLink,
  ...otherProps
}) => {
  return (
    <SectionCompanyFounder background={background} {...otherProps}>
      <div className="section-company-founder--container">
        <h2 className="section-company-founder--title">{title}</h2>

        <div className="section-company-founder--content">
          {founders.map((founderItem, index) => {
            return (
              <div className="section-company-founder--item" key={index}>
                <div className="section-company-founder--image-wrapper">
                  <WebsiteImage image={founderItem} />
                </div>
                <h2 className="section-company-founder--item-name font-h4">{founderItem.title}</h2>
                <p className="section-company-founder--item-company-position">
                  {founderItem.description}
                </p>
              </div>
            );
          })}
        </div>
        <Button
          type="secondary"
          className="section-company-founder--button"
          dataPosition="Company Body - Meet Founders CTA"
          to={buttonLink}
        >
          {buttonTitle}
        </Button>
      </div>
    </SectionCompanyFounder>
  );
};

CompanyFounderSection.propTypes = {
  title: PropTypes.string,
  founders: PropTypes.array,
};

const SectionCompanyFounder = styled.div`
  background: ${(props) => props.background};

  .section-company-founder--container {
    padding: 81px 28px 110px;
    max-width: 1040px;
    margin: 0 auto;
    text-align: center;

    .section-company-founder--title {
      text-align: center;
      color: var(--white);
    }

    .section-company-founder--content {
      display: flex;
      max-width: 784px;
      padding: 0;
      margin: 75px auto 0;

      .section-company-founder--item {
        width: 100%;
        padding: 0 21px;

        .section-company-founder--image-wrapper {
          margin: 0 auto;
          align-items: center;
          justify-content: center;
          width: 100%;
          .gatsby-image-wrapper {
            img {
              width: 100%;
              object-fit: contain;
            }
          }
        }

        .section-company-founder--item-name {
          color: var(--white);
          text-align: left;
          margin-top: 16px;
        }

        .section-company-founder--item-company-position {
          text-align: left;
          color: var(--white);
          margin: 0;
        }
      }
    }

    .section-company-founder--button {
      margin: 80px auto 0;
      padding: 20px 24px;
    }
  }

  @media (max-width: 991px) {
  }

  @media (max-width: 767px) {
    .section-company-founder--container {
      padding: 48px 18px;

      .section-company-founder--title {
        letter-spacing: var(--letter-spacing--0-03);
      }

      .section-company-founder--content {
        max-width: 610px;
        margin-top: 40px;

        .section-company-founder--item {
          padding: 0 10px;
        }
      }

      .section-company-founder--button {
        margin-top: 40px;
        padding: 15px 24px;
        font-size: var(--font-size-16);
      }
    }
  }

  @media (max-width: 575px) {
    .section-company-founder--container {
      padding: 48px 18px;

      .section-company-founder--content {
        flex-direction: column;

        .section-company-founder--item {
          max-width: 279px;
          margin: 0 auto 24px;
        }
      }
    }
  }
`;

export default CompanyFounderSection;
