import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SimpleTextButtonSection from 'components/Sections/SimpleTextButtonSection';

const CompanyReadySection = ({
  backgroundImg,
  title,
  buttonTitle,
  buttonType,
  buttonLinkTo,
  colorScheme,
  ...otherProps
}) => {
  return (
    <SectionCompanyMilestones {...otherProps}>
      <SimpleTextButtonSection
        backgroundImg={backgroundImg}
        colorScheme={colorScheme || 'Blue and Orange'}
        title={title}
        buttonTitle={buttonTitle}
        buttonLinkTo={buttonLinkTo}
      />
    </SectionCompanyMilestones>
  );
};

CompanyReadySection.propTypes = {
  title: PropTypes.string,
  backgroundImg: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonType: PropTypes.string,
  buttonLinkTo: PropTypes.string,
};

const SectionCompanyMilestones = styled.div`
  .section-text-button--container {
    padding: 128px 0;
    @media (max-width: 767px) {
      padding: 80px 0;
    }
  }
`;

export default CompanyReadySection;
