import React from 'react';
import { graphql } from 'gatsby';
import useCompanyTimeline from 'hooks/useCompanyTimeline';
import WebsiteLayout from 'layouts/websiteLayout';
import SEO, { createSEO } from 'components/Core/SEO';
import CompanyFounderSection from 'components/Page/Company/CompanyFounderSection';
import CompanyHeroSection from 'components/Page/Company/CompanyHeroSection';
import CompanyLetterSection from 'components/Page/Company/CompanyLetterSection';
import CompanyLogoSection from 'components/Page/Company/CompanyLogoSection';
import CompanyMilestonesSection from 'components/Page/Company/CompanyMilestonesSection';
import CompanyReadySection from 'components/Page/Company/CompanyReadySection';
import CompanyTrustSection from 'components/Page/Company/CompanyTrustSection';

const CompanyPage = (props) => {
  const { components } = props.data.contentfulComponentCollection;

  const fetchEntries = (filter) => components.find((ele) => ele.__typename === filter);
  const fetchSections = (filter) => components.find((ele) => ele.slug === filter);

  const seo = createSEO(fetchEntries('ContentfulSeo'), props);
  const heroContent = fetchEntries('ContentfulDynamicHero');
  const letterContent = fetchEntries('ContentfulRichText');
  const trustedSection = fetchSections('Company Page - TrustBar');
  const companieLogoSection = fetchSections('Company Page - Company Logos');
  const founderSection = fetchSections('Company Page - Meet Our Founders');
  const foundersCta = founderSection?.components[0];
  const viewJobSection = fetchSections('Company Page - View Job Openings');
  const viewJobCta = viewJobSection.components?.[0];
  const milestones = useCompanyTimeline();

  return (
    <WebsiteLayout hero={heroContent}>
      <SEO {...seo} />
      <CompanyHeroSection
        backgroundImg={heroContent.backgroundImage?.image?.file?.url || ''}
        title={heroContent.heading1}
        titleColor="white"
        description={heroContent.subhead}
        descriptionColor="white"
        video={heroContent.popupVideo?.url}
      />
      <CompanyLetterSection background="var(--white)" letter={letterContent?.content} />
      <CompanyTrustSection background="var(--skyline-1)" trustedSection={trustedSection} />
      <CompanyLogoSection background="var(--white)" logos={companieLogoSection.imgs} />
      <CompanyFounderSection
        background="var(--dark)"
        title={founderSection.title}
        buttonTitle={foundersCta.buttonLabel}
        buttonType="secondary"
        buttonLink={foundersCta.url}
        founders={founderSection.imgs}
      />
      <CompanyMilestonesSection
        background="var(--skyline-1)"
        title="Milestones"
        milestones={milestones}
      />
      <CompanyReadySection
        title={viewJobSection.title}
        buttonTitle={viewJobCta?.buttonLabel || ''}
        buttonLinkTo={viewJobCta?.url || ''}
      />
    </WebsiteLayout>
  );
};

export default CompanyPage;

export const CompanyPageQuery = graphql`
  query CompanyPageQuery {
    contentfulComponentCollection(contentful_id: { eq: "7L6Sn5w52LvyUgRlCwEtuc" }) {
      ... on ContentfulComponentCollection {
        components {
          ...Seo
          ...DynamicHero
          ...RichText
          ... on ContentfulStackedContentBlock {
            __typename
            contentful_id
            slug
            title
            imgs {
              ...Image
            }
            components {
              ...CallToAction
            }
          }
        }
      }
    }
  }
`;
