import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StyledRichText from 'components/RichText/StyledRichText';

const CompanyLetterSection = ({ background, letter, ...otherProps }) => {
  return (
    <SectionCompanyLetter background={background} {...otherProps}>
      <div className="section-company-letter--container">
        <StyledRichText content={letter} />
      </div>
    </SectionCompanyLetter>
  );
};

CompanyLetterSection.propTypes = {
  background: PropTypes.string,
  letter: PropTypes.object,
};

const SectionCompanyLetter = styled.div`
  background: ${(props) => props.background};

  .section-company-letter--container {
    padding: 72px 28px;
    max-width: 740px;
    margin: 0 auto;
    text-align: center;

    h2 {
      font-size: var(--font-size-44);
      text-align: center;
      margin: 0 auto 50px;
    }

    p {
      text-align: left;
      margin: 0 auto 18px;
    }

    h5 {
      color: var(--dark);
      text-align: left;

      margin: 25px auto 0;
    }

    .blog-block-quote--content {
      .blog-block-quote--author-sharing {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    .section-company-letter--container {
      padding: 48px 28px;
      max-width: 522px;

      h2 {
        letter-spacing: var(--letter-spacing--0-03);
        margin-bottom: 32px;
      }

      p {
        font-size: var(--font-size-16);
      }

      h5 {
        font-size: var(--font-size-18);
      }
    }
  }

  @media (max-width: 575px) {
    .section-company-letter--container {
      padding: 48px 20px;
    }
  }
`;

export default CompanyLetterSection;
