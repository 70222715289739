import { graphql, useStaticQuery } from 'gatsby';

const useCompanyTimeline = () => {
  const { contentfulComponentCollection } = useStaticQuery(graphql`
    query {
      contentfulComponentCollection(contentful_id: { eq: "5N9RFKVkd9IsH7l0uVQ3Jc" }) {
        components {
          ... on ContentfulNavigationElement {
            __typename
            label
            description
          }
        }
      }
    }
  `);

  return contentfulComponentCollection?.components;
};

export default useCompanyTimeline;
